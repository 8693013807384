body {
  margin: 0;
}

h1 {
  text-align: center;
}

.loanOfficer {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.form-container {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 20px;
  background-color: #fafbfe;
  height: 100vh;
}

.input {
  background-color: rgba(240, 242, 245, 0.6);
}
.form-container .form {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
}

.formInside {
  padding: 20px;
}

.form-title {
  font-weight: 500;
  font-size: 22px;
}
.form-subtitle {
  padding: 8px 0;
  font-weight: 500;
}
.error-message {
  font-weight: 500;
  color: red;
  font-size: 1.4rem;
}
.warning-message {
  font-size: 14px;
  padding: 12px 0;
}

.submitButton {
  text-align: center;
  margin: 20px;
  margin-top: 40px;
}

.submitButton button {
  background-color: #4caf50;
  font-size: 18px;
}

.shareButton {
  font-size: 18px !important;
  padding: 10 20px !important;
}

@media (max-width: 800px) {
  .submitButton {
    margin: 0;
    margin-top: 40px;
  }
  .submitButton button,
  .form .finalValue .shareButton {
    width: 100%;
  }
}

.finalValue {
  padding: 20px;
  text-align: center;
}

.finalValueResult {
  text-align: left;
  padding: 20px;
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 1.6rem !important;
}

input,
label,
.finalValue {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
}

.finalValue .shareButton {
  width: 160px;
  margin-top: 40px;
}
